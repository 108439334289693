export default function getLayout() {
    let gridElement = null;
    let sliderMenu = null;
    let sliderMenuItems = null;
    const itemsWidth = [];
    let clickCounter = 0;
    let leftArrowShow = false;
    let rightArrowShow = true;
    let showSort = false;

    function initializeLayout() {
        gridElement = document.querySelector('.grid');
        sliderMenu = document.getElementById('sliderMenu');
        sliderMenuItems = document.querySelectorAll('.slider-menu-item');

        // if the sliderMenu can scroll, show the rightArrow
        rightArrowShow = sliderMenu.scrollWidth > sliderMenu.clientWidth;

        // Take width from each menuItem and push it into an array
        Array.from(sliderMenuItems).forEach((item) => {
            let itemMargin = getComputedStyle(item).marginRight.slice(0, -2);
            let itemMarginNum = parseInt(itemMargin);
            let itemTotalWidth = item.offsetWidth + itemMarginNum;
            itemsWidth.push(itemTotalWidth);
        });

        // scroll to active category
        for (let i = 0; i < sliderMenuItems.length; i++) {
            if (sliderMenuItems[i].classList.contains('active')) sliderMenuItems[i].scrollIntoView();
        }
    }

    function horizontalScroll(direction) {
        if (direction === 'left') {
            sliderMenu.scrollLeft -= itemsWidth[clickCounter];
            clickCounter = clickCounter > 0 ? clickCounter - 1 : 0;
            return;
        }

        if (direction === 'right') {
            const maxIndex = itemsWidth.length - 1;
            sliderMenu.scrollLeft += itemsWidth[clickCounter];
            clickCounter = clickCounter < maxIndex ? clickCounter + 1 : maxIndex;
            return;
        }

        throw new Error('Unknown direction: ' + direction);
    }

    function hideSliderArrows() {
        // show left arrow if there is a space for scrolling to the left
        leftArrowShow = sliderMenu.scrollLeft > 0;

        // show right arrow if there is a space for scrolling to the right
        const scrollRight = sliderMenu.scrollWidth - sliderMenu.scrollLeft - sliderMenu.offsetWidth;
        rightArrowShow = scrollRight > 0;
    }

    function displayMapView() {
        gridElement.classList.add('map-grid-container');
        gridElement.classList.remove('filters-grid-container');
        window.dispatchEvent(new Event('mapViewDisplayed'));
    }

    function displayListView() {
        gridElement.classList.add('filters-grid-container');
        gridElement.classList.remove('map-grid-container');
        window.dispatchEvent(new Event('listViewDisplayed'));
    }

    function displaySort() {
        showSort = !showSort;
    }

    function stickFiltersControls(event) {
        let filtersContent = event.target;

        gridElement.classList.add('sticky');

        if (filtersContent.scrollTop === 0) {
            gridElement.classList.remove('sticky');
        }
    }

    return {
        initializeLayout,
        horizontalScroll,
        hideSliderArrows,
        displayMapView,
        displayListView,
        displaySort,
        stickFiltersControls,
        leftArrowShow,
        rightArrowShow,
        showSort,
    };
}
